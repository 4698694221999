import { createContext, useContext } from 'react';

import { BrandedSnippetDecoration } from 'lux/components/BrandedSnippet/brandedSnippet.types';

const brandedSnippetContext = createContext<BrandedSnippetDecoration | null>(null);

export default brandedSnippetContext;

export const useBrandedSnippetContext = (): BrandedSnippetDecoration | null => {
    const value = useContext(brandedSnippetContext);
    return value;
};
