import { FC } from 'react';

import BlacklistButton from 'lux/components/VacancySearchItem/BlacklistButton';
import Favorite from 'lux/components/VacancySearchItem/Favorite';
import { useCardContainerClassNames } from 'lux/components/VacancySearchItem/hooks/useCardContainerClassNames';
import Source from 'lux/components/VacancySearchItem/types/Source';
import useMagritte from 'lux/hooks/useMagritte';

import styles from './styles.less';

type Props = {
    vacancyId: number;
    employerId: number;
    isClosedVacancy: boolean;
    isAnonymousVacancy: boolean;
    clickUrl: string;
    isAdv: boolean;
    vacancySource: Source;
};

const ActionButtons: FC<Props> = ({
    vacancyId,
    employerId,
    isClosedVacancy,
    isAnonymousVacancy,
    clickUrl,
    isAdv,
    vacancySource,
}) => {
    const isMagritte = useMagritte();
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();

    if (!isMagritte) {
        return null;
    }

    return (
        <>
            <div className={wideCardContainerClassName}>
                <div className={styles.actionsContainerWide}>
                    <Favorite
                        vacancyId={vacancyId}
                        employerId={employerId}
                        isClosedVacancy={isClosedVacancy}
                        clickUrl={clickUrl}
                        isAdv={isAdv}
                        vacancySource={vacancySource}
                    />
                    <BlacklistButton
                        vacancyId={vacancyId}
                        employerId={employerId}
                        isAnonymousVacancy={isAnonymousVacancy}
                    />
                </div>
            </div>

            <div className={narrowCardContainerClassName}>
                <div className={styles.actionsContainerNarrow}>
                    <Favorite
                        vacancyId={vacancyId}
                        employerId={employerId}
                        isClosedVacancy={isClosedVacancy}
                        clickUrl={clickUrl}
                        isAdv={isAdv}
                        vacancySource={vacancySource}
                    />
                    <BlacklistButton
                        vacancyId={vacancyId}
                        employerId={employerId}
                        isAnonymousVacancy={isAnonymousVacancy}
                    />
                </div>
            </div>
        </>
    );
};

export default ActionButtons;
