import { Link } from '@hh.ru/redux-spa-middleware';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useCardContainerClassNames } from 'lux/components/VacancySearchItem/hooks/useCardContainerClassNames';
import translation from 'lux/components/translation';

import styles from './styles.less';

type BottomRowInfoProps = {
    advClickUrl?: string;
    shouldShowAdv?: boolean;
};

const TrlKeys = {
    advMark: 'vacancyItem.advMark',
    clickme: 'vacancy.serp.clickMe',
};

const ADV_URL =
    'https://clickme.hh.ru/new/dashboard?utm_source=hh.ru&utm_medium=referral&utm_campaign=vacancies_results';

const BottomRowInfo: TranslatedComponent<BottomRowInfoProps> = ({ advClickUrl, shouldShowAdv = false, trls }) => {
    const showAdvMark = Boolean(advClickUrl);

    const { narrowCardContainerClassName } = useCardContainerClassNames();
    if (!showAdvMark && !shouldShowAdv) {
        return null;
    }

    return (
        <div className={narrowCardContainerClassName}>
            <VSpacing base={6} />
            <div className={styles.cardFooter}>
                {showAdvMark && (
                    <Text size={TextSize.Small} Element="span">
                        <span className={styles.advClickWarningText}>{trls[TrlKeys.advMark]}</span>
                    </Text>
                )}

                <div />

                {shouldShowAdv && (
                    <Link to={ADV_URL}>
                        <Text size={TextSize.Small} Element="span">
                            <span className={styles.unimportantText}>{trls[TrlKeys.clickme]}</span>
                        </Text>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default translation(BottomRowInfo);
