import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    errorText: 'vacancyResponseAbort.error',
};

export const APPLICANT_RESPONSE_ABORT = 'APPLICANT_RESPONSE_ABORT';
const SubscriptionErrorNotificationError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.errorText]}</>;
export default {
    Element: translation(SubscriptionErrorNotificationError),
    kind: 'error',
    autoClose: true,
};
