import React from 'react';
import classnames from 'classnames';

import Gap from 'bloko/blocks/gap';

import { LightPageCommonProps } from 'lux/components/PageLayout/Layouts/LightPageLayout/lightPage.types';

const LightPageLayoutContent =
    ({
        error: ErrorComponent,
        noContentPadding,
        noBackground,
    }: LightPageCommonProps): React.FC<React.PropsWithChildren> =>
    ({ children }) =>
        (
            <Gap sTop sBottom mTop mBottom lTop lBottom>
                {ErrorComponent && <ErrorComponent />}
                <div
                    className={classnames('light-page-content', {
                        'light-page-content_no-padding': noContentPadding,
                        'light-page-content_no-background': noBackground,
                    })}
                >
                    {children}
                </div>
            </Gap>
        );

export default LightPageLayoutContent;
