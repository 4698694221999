import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CompensationFormattedText from 'lux/components/Compensation';
import FakeMagrittePrimaryText from 'lux/components/VacancySearchItem/FakeMagrittePrimaryText';
import { useCardContainerClassNames } from 'lux/components/VacancySearchItem/hooks/useCardContainerClassNames';
import translation from 'lux/components/translation';
import useMagritte from 'lux/hooks/useMagritte';
import VacancyCompenstionType from 'lux/models/vacancy/compensation.type';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';

import styles from './styles.less';

interface CompensationProps {
    compensation: VacancyCompenstionType;
}

const TrlKeys = {
    net: 'employer.newVacancy.compensation.net',
    gross: 'employer.newVacancy.compensation.gross',
};

const Compensation: TranslatedComponent<CompensationProps> = ({ compensation, trls }) => {
    const isMagritte = useMagritte();
    const { separateLineOnNarrowCardClassName } = useCardContainerClassNames();

    if (!compensation.from && !compensation.to) {
        return null;
    }

    const compensationElement = (
        <>
            <CompensationFormattedText
                from={compensation.from}
                to={compensation.to}
                currencyCode={compensation.currencyCode}
                dash
            />
            {NON_BREAKING_SPACE}
            {trls[compensation.gross ? TrlKeys.gross : TrlKeys.net]}
        </>
    );

    if (isMagritte) {
        return (
            <Text Element="span" typography="label-1-regular">
                {compensationElement}
            </Text>
        );
    }

    return (
        <FakeMagrittePrimaryText
            Element="span"
            className={classnames(styles.compensationText, separateLineOnNarrowCardClassName)}
        >
            {compensationElement}
        </FakeMagrittePrimaryText>
    );
};

export default translation(Compensation);
