import React from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import { H1 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';

import DefaultLayout from 'lux/components/PageLayout/Layouts/DefaultLayout';

interface HeaderLayoutProps {
    /** Обвязка содержимого страницы */
    linkAboveHeader?: { to: string; trl: string };
    /** Заголовок страницы */
    title?: string;
}

const HeaderLayout: React.FC<HeaderLayoutProps & React.PropsWithChildren> = ({ linkAboveHeader, children, title }) => (
    <DefaultLayout>
        <Column xs="4" s="8" m="12" l="16">
            {linkAboveHeader && (
                <BlokoLink disableVisited Element={Link} to={linkAboveHeader.to}>
                    {linkAboveHeader.trl}
                </BlokoLink>
            )}
            <H1>{title}</H1>
            <VSpacing base={4} />
        </Column>
        {children}
    </DefaultLayout>
);

export default HeaderLayout;
