import { FC } from 'react';

import HSpacing from 'bloko/blocks/hSpacing';
import Text from 'bloko/blocks/text';

import { DOT } from 'lux/modules/symbols';

import styles from './styles.less';

const Divider: FC = () => {
    return (
        <>
            <HSpacing base={2} />
            <Text Element="span">
                <span className={styles.divider}>{DOT}</span>
            </Text>
            <HSpacing base={2} />
        </>
    );
};

export default Divider;
