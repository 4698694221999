import { FC } from 'react';

import { Badge } from 'lux/models/vacancyView.types';
import { useSelector } from 'lux/modules/useSelector';

import EmployerHHRatingIcon, { RatingIconSize } from 'lux/components/EmployerHHRatingIcon';

type Props = {
    badge: Badge;
    isVacancyPage?: boolean;
    size?: RatingIconSize;
    dataQa: string;
    isRedesignedSerp?: boolean;
    isMagritte?: boolean;
};

const PrepareEmployerHHRating: FC<Props> = ({ badge, isVacancyPage, ...anyProps }) => {
    const userType = useSelector((state) => state.userType);

    const utmCampaign = isVacancyPage ? 'badge' : 'icon';
    const urlParams = `?utm_source=hh.ru&utm_medium=referral&utm_campaign=${utmCampaign}&utm_term=${userType}`;
    const data = {
        type: badge.type,
        url: `${badge.url}${urlParams}`,
        description: badge.description,
    };
    return <EmployerHHRatingIcon employerHHRating={data} {...anyProps} />;
};

export default PrepareEmployerHHRating;
