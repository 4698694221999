import { useDispatch } from 'react-redux';

import { Alert, Button as MagritteButton } from '@hh.ru/magritte-ui';
import { ExclamationCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind, ButtonScale } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Modal, { ModalTitle, ModalHeader, ModalContent, ModalFooter } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import useExperiment from 'lux/hooks/useExperiment';
import { useSelector } from 'lux/modules/useSelector';

const relocationWarningAction = makeSetStoreField('relocationWarning');

const TrlKeys = {
    title: 'vacancy.respond.popup.title',
    message: 'vacancy.respond.popup.message',
    country: 'vacancy.respond.popup.country',
    notice: 'vacancy.respond.popup.notice',
    confirm: 'vacancy.respond.popup.force',
    abort: 'vacancy.respond.popup.not',
    cancel: 'vacancy.respond.popup.cancel',
};

interface RelocationWarningPopupProps {
    vacancyId: number;
}

const RelocationWarningPopup: TranslatedComponent<RelocationWarningPopupProps> = ({ vacancyId, trls }) => {
    const dispatch = useDispatch();
    const relocationWarning = useSelector((state) => state.relocationWarning);

    const isMagritte = useExperiment('magritte_on_vacancy_response');

    if (relocationWarning?.vacancyId !== vacancyId) {
        return null;
    }

    const dismiss = () => dispatch(relocationWarningAction({ ...relocationWarning, confirmed: false, show: false }));
    const confirm = () => dispatch(relocationWarningAction({ ...relocationWarning, confirmed: true, show: false }));

    if (isMagritte) {
        return (
            <Alert
                visible={relocationWarning?.show}
                title={trls[TrlKeys.title]}
                description={trls[TrlKeys.message]}
                icon={<ExclamationCircleFilledSize24 />}
                buttons={
                    <>
                        <MagritteButton
                            mode="primary"
                            style="accent"
                            data-qa="relocation-warning-confirm"
                            onClick={confirm}
                        >
                            {trls[TrlKeys.confirm]}
                        </MagritteButton>
                        <MagritteButton
                            mode="secondary"
                            style="accent"
                            data-qa="relocation-warning-abort"
                            onClick={dismiss}
                        >
                            {trls[TrlKeys.cancel]}
                        </MagritteButton>
                    </>
                }
                layout="vertical"
                onClose={dismiss}
            />
        );
    }

    const regionTrl = relocationWarning.regionTrl;

    return (
        <Modal visible={relocationWarning?.show} onClose={dismiss} useBottomSheet>
            <ModalHeader>
                <ModalTitle data-qa="relocation-warning-title">{trls[TrlKeys.title]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <div className="respond-popup__notice respond-popup__notice_spaced" data-qa="relocation-warning-text">
                    <p>
                        {trls[TrlKeys.country]}
                        {regionTrl && `${regionTrl}.`}
                    </p>
                    <p>{trls[TrlKeys.notice]}</p>
                </div>
            </ModalContent>
            <ModalFooter>
                <Button
                    kind={ButtonKind.Success}
                    scale={ButtonScale.Small}
                    data-qa="relocation-warning-confirm"
                    onClick={confirm}
                >
                    {trls[TrlKeys.confirm]}
                </Button>
                <FormSpacer>
                    <Link appearance={LinkAppearance.Pseudo} data-qa="relocation-warning-abort" onClick={dismiss}>
                        {trls[TrlKeys.abort]}
                    </Link>
                </FormSpacer>
            </ModalFooter>
        </Modal>
    );
};

export default translation(RelocationWarningPopup);
