import React from 'react';

import { ColumnsWrapper } from 'bloko/blocks/column';

import RowContent from 'lux/components/RowContent';

interface DefaultLayoutProps {
    /** Сжатый контент */
    isShrinked?: boolean;
}

const DefaultLayout: React.FC<DefaultLayoutProps & React.PropsWithChildren> = ({ children, isShrinked }) => (
    <ColumnsWrapper>
        <RowContent isShrinked={isShrinked}>{children}</RowContent>
    </ColumnsWrapper>
);

export default DefaultLayout;
