import React, { Fragment } from 'react';
import { HeadProvider, Title, Meta, Link } from 'react-head';

const PageHead: React.FC<React.PropsWithChildren> = ({ children }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !process.env.LUX_SERVER ? <HeadProvider>{children}</HeadProvider> : <>{children}</>;
};

interface PageDescriptionProps {
    content: string;
    openGraphContent?: string;
}

const PageDescription: React.FC<PageDescriptionProps & React.PropsWithChildren> = ({ content, openGraphContent }) => {
    return (
        <Fragment>
            <Meta name="description" content={content} />
            <Meta property="og:description" content={openGraphContent || content} />
        </Fragment>
    );
};

interface PageKeywordsProps {
    content: string;
}

const PageKeywords: React.FC<PageKeywordsProps & React.PropsWithChildren> = ({ content }) => {
    return <Meta name="keywords" content={content} />;
};

export { PageHead, Title as PageTitle, Meta as PageMeta, PageDescription, PageKeywords, Link as PageLink };
