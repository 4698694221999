import { useLayoutEffect } from 'react';

import PageErrorWrapper from 'lux/components/PageErrorWrapper';
import { PageHead, PageTitle, PageMeta, PageDescription, PageKeywords, PageLink } from 'lux/components/PageHead';

import FullPageLayout from 'lux/components/PageLayout/FullPageLayout';
import DefaultLayout from 'lux/components/PageLayout/Layouts/DefaultLayout';
import EmptyLayout from 'lux/components/PageLayout/Layouts/EmptyLayout';
import HeaderLayout from 'lux/components/PageLayout/Layouts/HeaderLayout';
import LightPageLayout from 'lux/components/PageLayout/Layouts/LightPageLayout';
import SideBarLayout from 'lux/components/PageLayout/Layouts/SideBarLayout';
import { PageLayoutWithCustomLayout } from 'lux/components/PageLayout/PageLayout.types';

const RSS_MIME = 'application/rss+xml';

const PageLayout: PageLayoutWithCustomLayout = ({
    children,
    layout: LayoutComponent = DefaultLayout,
    errorWrapper: ErrorWrapperComponent = PageErrorWrapper,
    ogTitle,
    description,
    ogDescription,
    keywords,
    canonical,
    rssTitle,
    rssHref,
    removeTitle = false,
    afterMenuContent,
    useCleanContainer,
    ogUrl,
    ogType,
    ...layoutProps
}) => {
    const { title } = layoutProps;

    useLayoutEffect(() => {
        if (removeTitle || title) {
            const titleElement = document.querySelector('.HH-PageLayout-Title');
            titleElement?.parentNode?.removeChild(titleElement);
        }
    }, [removeTitle, title]);

    const content = (
        <>
            <PageHead>
                {title && (
                    <>
                        <PageTitle>{title}</PageTitle>
                        <PageMeta property="og:title" content={ogTitle || title} />
                    </>
                )}
                {description && <PageDescription content={description} openGraphContent={ogDescription} />}
                {keywords && <PageKeywords content={keywords} />}
                {canonical && <PageLink rel="canonical" href={canonical} />}
                {rssHref && (title || rssTitle) && (
                    <PageLink title={rssTitle || title} type={RSS_MIME} rel="alternate" href={rssHref} />
                )}
                {ogUrl && <PageMeta property="og:url" content={ogUrl} />}
                {ogType && <PageMeta property="og:type" content={ogType} />}
            </PageHead>
            <LayoutComponent {...layoutProps}>{children}</LayoutComponent>
        </>
    );

    return (
        <FullPageLayout afterMenuContent={afterMenuContent} useCleanContainer={useCleanContainer}>
            <ErrorWrapperComponent>{content}</ErrorWrapperComponent>
        </FullPageLayout>
    );
};

export { EmptyLayout, DefaultLayout, SideBarLayout, LightPageLayout, HeaderLayout };
export default PageLayout;
