import { useCallback, useRef } from 'react';
import classnames from 'classnames';

import { VSpacing, HSpacingContainer, HSpacing, Text } from '@hh.ru/magritte-ui';
import { ArchiveboxOutlinedSize16, InfoCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { ChatWritePossibility } from '@hh.ru/types-hh-microcore';
import VSpacingBloko from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancySearchItemActions from 'lux/components/VacancySearchItem/Actions';
import ChatikButton from 'lux/components/VacancySearchItem/ChatikButton';
import EtcButton from 'lux/components/VacancySearchItem/EtcButton';
import VacancySearchItemResponseButton from 'lux/components/VacancySearchItem/ResponseButton';
import ContactsButton from 'lux/components/VacancySearchItem/ShowContactsButton';
import { useCardContainerClassNames } from 'lux/components/VacancySearchItem/hooks/useCardContainerClassNames';
import { useWorkflowState } from 'lux/components/VacancySearchItem/hooks/useWorkflowState';
import Source from 'lux/components/VacancySearchItem/types/Source';
import translation from 'lux/components/translation';
import useMagritte from 'lux/hooks/useMagritte';
import { UserType } from 'lux/models/userType';
import { VacancyAddress } from 'lux/models/vacancyView.types';
import { useSelector } from 'lux/modules/useSelector';
import { sendAdvSearchVacancyAnalytics, AdvAnalyticsTypes } from 'lux/utils/sendAdvAnalytics';

import styles from './styles.less';

const Features = {
    hideInterfaceForModerators: 'hide_interface_for_moderators',
};

const TrlKeys = {
    movedToArchive: 'vacancySearch.vacancyCard.movedToArchive',
    archived: 'applicant.negotiations.vacancyArchived',
    responded: 'vacancySearchResults.applicantflags.responded',
    youWereInvited: 'vacancySearchResults.applicantflags.youWereInvited',
    discard: 'vacancySearchResults.applicantflags.rejected',
    chat: 'vacancySearchResults.chat',
};

type MainButtonsProps = {
    vacancyId: number;
    vacancySource: Source;
    companyId: number;
    showContact: boolean;
    chatWritePossibility: ChatWritePossibility;
    vrImmediateRedirectUrl?: string;
    vacancyType: string;
    clickUrl?: string;
    vacancyOfTheDayClickUrl: string | null;
    address?: VacancyAddress;
    isAdv?: boolean;
    isArchived: boolean;
    isAnonymousVacancy: boolean;
};

const MainButtons: TranslatedComponent<MainButtonsProps> = ({
    vacancyId,
    vacancySource,
    companyId,
    showContact,
    chatWritePossibility,
    vrImmediateRedirectUrl,
    clickUrl,
    vacancyOfTheDayClickUrl,
    address,
    isAdv,
    isArchived,
    isAnonymousVacancy,
    trls,
}) => {
    const isXs = useBreakpoint() === Breakpoint.XS;
    const hideInterfaceForModerators = useSelector(({ features }) => features[Features.hideInterfaceForModerators]) as
        | string
        | undefined;
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();

    const handleContactsClickRef = useRef({});
    const userType = useSelector((state) => state.userType);
    const userId = useSelector(({ userId }) => userId);
    const currentEmployerId = useSelector((state) => state.employerId);
    const shouldHideInterfaceForModerators = hideInterfaceForModerators?.split(',')?.includes(userId);
    const isMagritte = useMagritte();
    const resolveContactButtonVisibility = () => {
        if (isXs && userType === UserType.Anonymous) {
            return false;
        }
        if (userType === UserType.Employer && Number(currentEmployerId) !== companyId) {
            return false;
        }
        return showContact;
    };
    const isContactsButtonVisible = resolveContactButtonVisibility();
    const isResponseButtonVisible = userType !== UserType.Employer || vacancySource === Source.EmployerVacancy;
    const isAnyButtonVisibleMagritte = isResponseButtonVisible || isContactsButtonVisible || isArchived;

    const onShowContactsButtonClick = useCallback(
        () => sendAdvSearchVacancyAnalytics({ clickUrl, isAdv, analyticsType: AdvAnalyticsTypes.ContactsButton }),
        [clickUrl, isAdv]
    );

    const { isResponded, isInvited, isDiscarded, showWorkflowStatus } = useWorkflowState(vacancyId);

    if (shouldHideInterfaceForModerators) {
        return null;
    }

    const showControlSpacer = userType !== UserType.Employer || vacancySource === Source.EmployerVacancy;

    const getWorkflowStatusElement = () => {
        if (isResponded) {
            return (
                <div className={classnames(styles.workflowStatusContainer, styles.workflowStatusContainerResponded)}>
                    <InfoCircleOutlinedSize16 initial="accent" />
                    <HSpacing default={12} />
                    <Text typography="subtitle-1-semibold" style="accent">
                        {trls[TrlKeys.responded]}
                    </Text>
                </div>
            );
        } else if (isInvited) {
            return (
                <div className={classnames(styles.workflowStatusContainer, styles.workflowStatusContainerInvited)}>
                    <InfoCircleOutlinedSize16 initial="positive" />
                    <HSpacing default={12} />
                    <Text typography="subtitle-1-semibold" style="positive">
                        {trls[TrlKeys.youWereInvited]}
                    </Text>
                </div>
            );
        } else if (isDiscarded) {
            return (
                <div className={classnames(styles.workflowStatusContainer, styles.workflowStatusContainerDiscarded)}>
                    <InfoCircleOutlinedSize16 initial="warning-secondary" />
                    <HSpacing default={12} />
                    <Text typography="subtitle-1-semibold" style="warning-secondary">
                        {trls[TrlKeys.discard]}
                    </Text>
                </div>
            );
        }

        return null;
    };

    if (isMagritte) {
        if (!isAnyButtonVisibleMagritte) {
            return null;
        }

        if (isArchived) {
            return (
                <>
                    <VSpacing default={12} />

                    <div className={styles.archived}>
                        <ArchiveboxOutlinedSize16 initial="secondary" />
                        <HSpacing default={24} />
                        <Text Element="span" typography="subtitle-1-semibold" style="secondary">
                            {trls[TrlKeys.archived]}
                        </Text>
                    </div>
                </>
            );
        }

        return (
            <>
                <VSpacing default={12} />

                <div className={wideCardContainerClassName}>
                    <HSpacingContainer default={12}>
                        {showWorkflowStatus ? (
                            <>
                                {getWorkflowStatusElement()}

                                <ChatikButton vacancyId={vacancyId} vacancySource={vacancySource}>
                                    {trls[TrlKeys.chat]}
                                </ChatikButton>
                            </>
                        ) : (
                            <VacancySearchItemResponseButton
                                vacancyId={vacancyId}
                                vacancySource={vacancySource}
                                employerId={companyId}
                                vrImmediateRedirectUrl={vrImmediateRedirectUrl}
                                clickUrl={clickUrl}
                                isAdv={isAdv}
                                isXs={isXs}
                                vacancyOfTheDayClickUrl={vacancyOfTheDayClickUrl}
                                isResponseButtonVisible={isResponseButtonVisible}
                            />
                        )}

                        <ContactsButton
                            showContact={isContactsButtonVisible}
                            vacancyId={vacancyId}
                            address={address}
                            employerId={companyId}
                            chatWritePossibility={chatWritePossibility}
                            onClick={onShowContactsButtonClick}
                            handleContactsClickRef={handleContactsClickRef}
                        />
                    </HSpacingContainer>
                </div>

                <div className={narrowCardContainerClassName}>
                    {showWorkflowStatus ? (
                        <>
                            {getWorkflowStatusElement()}

                            <VSpacing default={12} />

                            <HSpacingContainer default={12}>
                                <div className={styles.afterResponseButtonMobile}>
                                    <ChatikButton vacancyId={vacancyId} vacancySource={vacancySource} stretched>
                                        {trls[TrlKeys.chat]}
                                    </ChatikButton>
                                </div>

                                {isContactsButtonVisible && (
                                    <div className={styles.afterResponseButtonMobile}>
                                        <ContactsButton
                                            showContact={isContactsButtonVisible}
                                            vacancyId={vacancyId}
                                            address={address}
                                            employerId={companyId}
                                            chatWritePossibility={chatWritePossibility}
                                            onClick={onShowContactsButtonClick}
                                            handleContactsClickRef={handleContactsClickRef}
                                            stretched
                                        />
                                    </div>
                                )}
                            </HSpacingContainer>
                        </>
                    ) : (
                        <HSpacingContainer default={12}>
                            <VacancySearchItemResponseButton
                                vacancyId={vacancyId}
                                vacancySource={vacancySource}
                                employerId={companyId}
                                vrImmediateRedirectUrl={vrImmediateRedirectUrl}
                                clickUrl={clickUrl}
                                isAdv={isAdv}
                                isXs={isXs}
                                vacancyOfTheDayClickUrl={vacancyOfTheDayClickUrl}
                                isNoPopups
                                isResponseButtonVisible={isResponseButtonVisible}
                            />
                            <ContactsButton
                                showContact={isContactsButtonVisible}
                                vacancyId={vacancyId}
                                address={address}
                                employerId={companyId}
                                chatWritePossibility={chatWritePossibility}
                                onClick={onShowContactsButtonClick}
                                handleContactsClickRef={handleContactsClickRef}
                            />
                        </HSpacingContainer>
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <VSpacingBloko base={4} xs={6} />

            <div className={styles.controlsContainer}>
                {!isArchived && (
                    <VacancySearchItemResponseButton
                        vacancyId={vacancyId}
                        vacancySource={vacancySource}
                        employerId={companyId}
                        vrImmediateRedirectUrl={vrImmediateRedirectUrl}
                        clickUrl={clickUrl}
                        isAdv={isAdv}
                        isXs={isXs}
                        vacancyOfTheDayClickUrl={vacancyOfTheDayClickUrl}
                        isResponseButtonVisible={isResponseButtonVisible}
                    />
                )}
                {showControlSpacer && <span className={styles.controlsSpacer} />}
                <ContactsButton
                    showContact={isContactsButtonVisible}
                    vacancyId={vacancyId}
                    address={address}
                    employerId={companyId}
                    chatWritePossibility={chatWritePossibility}
                    onClick={onShowContactsButtonClick}
                    handleContactsClickRef={handleContactsClickRef}
                />
                <VacancySearchItemActions
                    vacancyId={vacancyId}
                    employerId={companyId}
                    isAnonymousVacancy={isAnonymousVacancy}
                />
                <EtcButton
                    showContact={isContactsButtonVisible}
                    vacancyId={vacancyId}
                    handleContactsClickRef={handleContactsClickRef}
                    vacancySource={vacancySource}
                />
            </div>
        </>
    );
};

export default translation(MainButtons);
