import { useEffect, useState } from 'react';

const checkLoadImage = (url: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => {
            resolve();
        });
        image.addEventListener('error', () => {
            reject();
        });
        image.src = url;
    });
};

/**
 * Чтобы избежать повторных запросов рисунков необходимо
 *  использовать только мемоизированный массив imageUrls
 */

const useImagesLoader = (
    imageUrls: string[],
    isImagesLoaderAllowed: boolean
): { isLoading: boolean; hasLoadingError: boolean } => {
    const [isLoading, setIsLoading] = useState(isImagesLoaderAllowed);
    const [hasLoadingError, setHasLoadingError] = useState(false);

    useEffect(() => {
        const loadImages = async () => {
            setHasLoadingError(false);
            setIsLoading(true);
            const promises = imageUrls.map((src) => checkLoadImage(src));
            try {
                await Promise.all(promises);
            } catch (error) {
                setHasLoadingError(true);
            } finally {
                setIsLoading(false);
            }
        };
        if (isImagesLoaderAllowed) {
            void loadImages();
        }
    }, [isImagesLoaderAllowed, imageUrls]);

    return { isLoading, hasLoadingError };
};

export default useImagesLoader;
