import { Text, HSpacing, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import ConversionNumber from 'bloko/blocks/conversion';
import HSpacingBloko from 'bloko/blocks/hSpacing';
import TextBloko, { TextSize } from 'bloko/blocks/text';
import VSpacingBloko from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { useCardContainerClassNames } from 'lux/components/VacancySearchItem/hooks/useCardContainerClassNames';
import translation from 'lux/components/translation';
import useMagritte from 'lux/hooks/useMagritte';

import styles from './styles.less';

type VacancyTopRowInfoProps = {
    onlineUsersCount?: number;
    advClickUrl?: string;
    shouldShowAdv?: boolean;
};

const TrlKeys = {
    watching: {
        one: 'vacancySearch.card.views.one',
        some: 'vacancySearch.card.views.some',
        many: 'vacancySearch.card.views.many',
    },
    advMark: 'vacancyItem.advMark',
    clickme: 'vacancy.serp.clickMe',
};

const ADV_URL =
    'https://clickme.hh.ru/new/dashboard?utm_source=hh.ru&utm_medium=referral&utm_campaign=vacancies_results';

const VacancyTopRowInfo: TranslatedComponent<VacancyTopRowInfoProps> = ({
    onlineUsersCount,
    advClickUrl,
    shouldShowAdv,
    trls,
}) => {
    const isMagritte = useMagritte();
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();
    const showUsersCount = Boolean(onlineUsersCount && onlineUsersCount > 0);
    const showAdvMark = Boolean(advClickUrl);
    const showClickMe = Boolean(shouldShowAdv);

    if ((!showUsersCount && !showAdvMark && !showClickMe) || (isMagritte && !showClickMe && !showUsersCount)) {
        return null;
    }

    if (isMagritte) {
        return (
            <>
                {showClickMe && (
                    <>
                        <Text typography="label-4-regular" style="tertiary" Element="span">
                            {trls[TrlKeys.clickme]}
                        </Text>
                        <HSpacing default={12} />
                        <span className={narrowCardContainerClassName}>
                            <VSpacing default={8} />
                        </span>
                    </>
                )}

                {showUsersCount && (
                    <Text typography="label-4-regular" style="tertiary" Element="span">
                        <ConversionNumber
                            value={onlineUsersCount as number}
                            one={trls[TrlKeys.watching.one]}
                            some={trls[TrlKeys.watching.some]}
                            many={trls[TrlKeys.watching.many]}
                            hasValue={false}
                            format={(trl) =>
                                format(trl, {
                                    '{0}': onlineUsersCount as number,
                                })
                            }
                        />
                    </Text>
                )}
                <VSpacing default={8} />
            </>
        );
    }

    return (
        <span>
            <span className={wideCardContainerClassName}>
                {showAdvMark && (
                    <>
                        <TextBloko size={TextSize.Small} Element="span">
                            <span className={styles.advClickWarningText}>{trls[TrlKeys.advMark]}</span>
                        </TextBloko>
                        <HSpacingBloko base={6} />
                    </>
                )}

                {showClickMe && (
                    <>
                        <Link to={ADV_URL}>
                            <TextBloko size={TextSize.Small} Element="span">
                                <span className={styles.unimportantText}>{trls[TrlKeys.clickme]}</span>
                            </TextBloko>
                        </Link>
                        <HSpacingBloko base={6} />
                    </>
                )}
            </span>

            {showUsersCount && (
                <TextBloko size={TextSize.Small} Element="span">
                    <span className={styles.unimportantText}>
                        <ConversionNumber
                            value={onlineUsersCount as number}
                            one={trls[TrlKeys.watching.one]}
                            some={trls[TrlKeys.watching.some]}
                            many={trls[TrlKeys.watching.many]}
                            hasValue={false}
                            format={(trl) =>
                                format(trl, {
                                    '{0}': onlineUsersCount as number,
                                })
                            }
                        />
                    </span>
                </TextBloko>
            )}
            <VSpacingBloko base={0} xs={2} />
        </span>
    );
};

export default translation(VacancyTopRowInfo);
