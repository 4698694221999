import { updateUrl } from 'Modules/url';
import { createSearchVacancyOfTheDayClickUrl } from 'lux/components/VacanciesOfTheDay/Utils';

interface GetVacancyLinkURL {
    (params: {
        criteriaText: string;
        isAdv?: boolean;
        clickUrl?: string;
        advClickUrl?: string;
        desktopLink: string;
        isVacancyOfTheDay?: boolean;
    }): string;
}

// Для браузерной подсветки поверх encodeURIComponent экранируем символы [!'()*]
const fixedEncodeURIComponent = (str: string) =>
    encodeURIComponent(str).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);

// используем fixedEncodeURIComponent для экранирования
const fixedQueryStringify = (queryObject: Record<string, string>) =>
    Object.keys(queryObject).reduce((resultQueryString, paramKey) => {
        const newQueryParam = queryObject[paramKey]
            ? `${paramKey}=${fixedEncodeURIComponent(queryObject[paramKey])}`
            : '';

        return `${resultQueryString}${resultQueryString && newQueryParam ? '&' : ''}${newQueryParam}`;
    }, '');

export const getVacancyLinkURL: GetVacancyLinkURL = ({
    criteriaText,
    isAdv,
    clickUrl,
    advClickUrl,
    desktopLink,
    isVacancyOfTheDay,
}) => {
    const queryParams = fixedQueryStringify({
        query: criteriaText,
    });

    let url: string;

    switch (true) {
        case Boolean(isAdv && clickUrl):
            url = updateUrl(clickUrl as string, { clickType: 'link_to_vacancy' });
            break;
        case Boolean(advClickUrl):
            url = advClickUrl as string;
            break;
        default:
            url = `${desktopLink}${queryParams ? `?${queryParams}` : ''}`;
    }

    return createSearchVacancyOfTheDayClickUrl(url, isVacancyOfTheDay);
};
