import { FC, useRef } from 'react';

import { TooltipHover } from '@hh.ru/magritte-ui';
import { EmployersRatingOutlinedCircledSize16 } from '@hh.ru/magritte-ui/product';
import { Link } from '@hh.ru/redux-spa-middleware';
import HoverTip, { TipPlacement, TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import { EmployersRatingScaleSmallKindColor, EmployersRatingScaleMediumKindColor } from 'bloko/blocks/icon';

import { Badge } from 'lux/models/vacancyView.types';

const IconSizeMap = {
    small: EmployersRatingScaleSmallKindColor,
    medium: EmployersRatingScaleMediumKindColor,
} as const;

export type RatingIconSize = keyof typeof IconSizeMap;

type Props = {
    employerHHRating: Badge;
    size?: RatingIconSize;
    dataQa: string;
    isRedesignedSerp?: boolean;
    isMagritte?: boolean;
};

const EmployerHHRatingIcon: FC<Props> = ({
    employerHHRating,
    size = 'small',
    dataQa,
    isRedesignedSerp,
    isMagritte,
}) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    const IconComponent = IconSizeMap[size];

    if (isMagritte) {
        return (
            <Link
                to={employerHHRating.url}
                target="_blank"
                rel="nofollow noindex"
                data-qa={`${dataQa}${employerHHRating.type}` || ''}
            >
                <span ref={activatorRef}>
                    <EmployersRatingOutlinedCircledSize16 initial="warning-secondary" />
                </span>
                <TooltipHover direction="top" alignment="center" activatorRef={activatorRef}>
                    {employerHHRating.description}
                </TooltipHover>
            </Link>
        );
    }

    return (
        <Link
            to={employerHHRating.url}
            target="_blank"
            rel="nofollow noindex"
            data-qa={`${dataQa}${employerHHRating.type}` || ''}
        >
            <HoverTip
                render={() => employerHHRating.description}
                placement={TipPlacement.Top}
                layer={TipLayer.Overlay}
                host={!process.env.LUX_SERVER ? document.body : null}
            >
                {isRedesignedSerp ? <span className={'vacancy-serp-bage-hr-rating'} /> : <IconComponent scale={size} />}
            </HoverTip>
        </Link>
    );
};

export default EmployerHHRatingIcon;
