import { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { BottomSheet, NavigationBar, useBreakpoint } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonType, ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import Modal, { ModalContent, ModalFooter, ModalError } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';

import VacancyResponseError from 'lux/components/VacancyResponseError';
import VacancyResponseForm, { getUserResumes } from 'lux/components/VacancyResponseForm';
import Source from 'lux/components/VacancySearchItem/types/Source';
import translation from 'lux/components/translation';
import useMagritte from 'lux/hooks/useMagritte';
import { useSelector } from 'lux/modules/useSelector';

import ResumeDeliveredForm from 'lux/components/VacancyResponsePopup/BottomSheet/ResumeDeliveredForm';
import ResumeSelectForm from 'lux/components/VacancyResponsePopup/BottomSheet/ResumeSelectForm';
import { ResponseStep } from 'lux/components/VacancyResponsePopup/BottomSheet/responseSteps';
import PopupHeader from 'lux/components/VacancyResponsePopup/PopupHeader';

const Popup = ({
    visible,
    onClose,
    vacancyId,
    vacancyBodyFooterNodeRef,
    trls,
    vacancySource,
    isXs,
    isQuestionResponse,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const isFirstResponse = responseStatus.usedResumeIds.length === 0;
    const vacancy = responseStatus.shortVacancy;
    const [responseErrorCode, setResponseErrorCode] = useState(null);
    const responseImpossible = !responseStatus.unusedResumeIds.length;

    const lastResumeHash = useSelector((state) => state.lastVacancyResponseInfo?.resumeHash);
    const resumes = useMemo(() => getUserResumes(responseStatus), [responseStatus]);
    const [selectedResume, setSelectedResume] = useState(
        resumes.find((resume) => resume.hash === lastResumeHash) || resumes[0]
    );
    const [responseStep, setResponseStep] = useState(ResponseStep.Initial);
    const [responseData, setResponseData] = useState({});
    const postponedActions = useRef([]);

    const isMapVacancySearch = useSelector((state) => state.isMapVacancySearch);
    const isMagritte = useMagritte();
    const { isMobile } = useBreakpoint();

    const handleModalClose = () => {
        if (responseStep === ResponseStep.Done) {
            setTimeout(() => postponedActions.current.forEach((action) => action()), 300);
        }

        onClose(true);
        setResponseStep(ResponseStep.Initial);
    };

    const renderModalInner = (renderForm, formId, isLetterTextEmpty) => {
        if (responseStep === ResponseStep.Initial) {
            return (
                <>
                    <PopupHeader
                        isXs={isXs}
                        isQuestionResponse={isQuestionResponse}
                        selectedResume={selectedResume}
                        onXsClick={() => setResponseStep(ResponseStep.ResumeSelect)}
                        resumesCount={resumes.length}
                        vacancyId={vacancyId}
                    />
                    <ModalContent>{renderForm()}</ModalContent>
                    <ModalError visible={!!responseErrorCode}>
                        <div className="vacancy-response-popup-error" data-qa-popup-error-code={responseErrorCode}>
                            <VacancyResponseError
                                code={responseErrorCode}
                                letterMaxLength={responseStatus.letterMaxLength}
                            />
                        </div>
                    </ModalError>
                    <ModalFooter>
                        {!isXs && (
                            <>
                                <Button onClick={() => onClose(true)} data-qa="vacancy-response-popup-close-button">
                                    {trls[Popup.trls.cancel]}
                                </Button>
                                <FormSpacer />
                            </>
                        )}
                        <Button
                            form={formId}
                            type={ButtonType.Submit}
                            kind={ButtonKind.Primary}
                            data-qa="vacancy-response-submit-popup"
                            disabled={
                                responseImpossible ||
                                submitting ||
                                (isXs && vacancy['@responseLetterRequired'] && isLetterTextEmpty) ||
                                (isQuestionResponse && isLetterTextEmpty)
                            }
                            loading={submitting && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />}
                            stretched={isXs}
                        >
                            {isQuestionResponse
                                ? trls[Popup.trls.questionSubmit]
                                : trls[isFirstResponse ? Popup.trls.submit.default : Popup.trls.submit.repeat]}
                        </Button>
                        <VSpacing base={0} xs={1} />
                    </ModalFooter>
                </>
            );
        }
        if (responseStep === ResponseStep.ResumeSelect) {
            return (
                <ResumeSelectForm
                    isQuestionResponse={isQuestionResponse}
                    selectedResume={selectedResume}
                    resumes={resumes}
                    onClose={() => setResponseStep(ResponseStep.Initial)}
                    setSelectedResume={setSelectedResume}
                    vacancyId={vacancyId}
                />
            );
        }
        if (responseStep === ResponseStep.Done) {
            return (
                <ResumeDeliveredForm
                    isQuestionResponse={isQuestionResponse}
                    responseData={responseData}
                    isLetterTextEmpty={isLetterTextEmpty}
                    letterMaxLength={responseStatus.letterMaxLength}
                    vacancySource={vacancySource}
                    onClose={handleModalClose}
                />
            );
        }
        return null;
    };

    return (
        <VacancyResponseForm
            isQuestionResponse={isQuestionResponse}
            vacancyId={vacancyId}
            resumes={resumes}
            setSubmitting={setSubmitting}
            onResponse={isXs && !isQuestionResponse ? () => setResponseStep(ResponseStep.Done) : onClose}
            errorCode={responseErrorCode}
            onError={setResponseErrorCode}
            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
            vacancySource={vacancySource}
            isBottomSheet={isXs}
            selectedResume={selectedResume}
            setSelectedResume={setSelectedResume}
            setResponseData={setResponseData}
            postponedActions={postponedActions}
            render={({ renderForm, formId, isLetterTextEmpty }) => {
                // TODO: удалить после полной магритизации модального окна https://jira.hh.ru/browse/PORTFOLIO-31917
                // сейчас z-index модального окна блоко меньше, чем z-index боттомшита на магритте,
                // на карте карточки вакансий на мобильном размере экрана показываются в боттомшите,
                // поэтому при открытии этого модального окна, оно оказывается под боттомшитом с карточками вакансий
                // временно добавляем обертку магритта для контента этого окна без изменений
                // на карте вакансий в мобильном вебе
                if (isMapVacancySearch && isMagritte && isMobile) {
                    return (
                        <BottomSheet
                            header={
                                !isXs || responseStep === ResponseStep.Done ? (
                                    <NavigationBar right={{ icon: CrossOutlinedSize24, onClick: handleModalClose }} />
                                ) : undefined
                            }
                            height="full-screen"
                            visible={visible}
                            onClose={handleModalClose}
                        >
                            {renderModalInner(renderForm, formId, isLetterTextEmpty)}
                        </BottomSheet>
                    );
                }

                return (
                    <div
                        onClick={(e) => {
                            // клик вне области блоковского модального окна (или боттомшита) вызывает клик
                            // по магриттовской карточке вакансии
                            if (isMagritte) {
                                e.stopPropagation();
                            }
                        }}
                    >
                        <Modal
                            visible={visible}
                            onClose={handleModalClose}
                            closeButtonShow={!isXs || responseStep === ResponseStep.Done}
                            useBottomSheet
                        >
                            {renderModalInner(renderForm, formId, isLetterTextEmpty)}
                        </Modal>
                    </div>
                );
            }}
        />
    );
};

Popup.trls = {
    questionSubmit: 'vacancy.questionResponse.popup.submit',
    submit: {
        default: 'vacancy.response.popup.submit',
        repeat: 'vacancy.response.popup.submitAgain',
    },
    cancel: 'vacancy.response.popup.cancel',
};

Popup.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    vacancyId: PropTypes.number,
    vacancyBodyFooterNodeRef: PropTypes.object,
    vacancySource: PropTypes.oneOf(Object.values(Source)),
    isXs: PropTypes.bool,
    isQuestionResponse: PropTypes.bool,
    trls: PropTypes.object,
};

export default translation(Popup);
