import { useRef, useCallback, FC, DragEvent, ReactNode, MutableRefObject } from 'react';
import classnames from 'classnames';

import { Card, VSpacing } from '@hh.ru/magritte-ui';

import BrandedSnippet from 'lux/components/BrandedSnippet';
import BrandedSnippetProvider from 'lux/components/BrandedSnippet/context/BrandedSnippetProvider';
import ElementShownAnchor from 'lux/components/ElementShownAnchor';
import MagritteWrapper from 'lux/components/MagritteWrapper/MagritteWrapper';
import SearchType from 'lux/components/NovaFilters/SearchType';
import useVacancyOfTheDayAnalytics from 'lux/components/VacanciesOfTheDay/hooks/useVacancyOfTheDayViewAnalytics';
import translation from 'lux/components/translation';
import useSearchResultShown from 'lux/hooks/useSearchResultShown';
import { VacancySearchItem as VacancySearchItemType } from 'lux/models/vacancySearch/vacancySearchItem.types';
import { VacancyOfTheDayLocation } from 'lux/utils/sendAdvSpyAnalytics';

import VacancySearchItemContent from 'lux/components/VacancySearchItem/VacancySearchItemContent';
import { useMagritteOnVacancySearchExp } from 'lux/components/VacancySearchItem/hooks/useMagritteOnVacancySearchExp';
import Source from 'lux/components/VacancySearchItem/types/Source';
import { getVacancyLinkURL } from 'lux/components/VacancySearchItem/utils/getVacancyLinkURL';

import styles from './styles.less';

const VacancyOfTheDaySpyingProps = {
    elementLockTime: 2000,
    entryPercent: 0.5,
};

interface VacancySearchItemProps {
    vacancy: VacancySearchItemType;
    vacancySource: Source;
    hhtmFromLabel?: string;
    criteriaText?: string;
    isXs: boolean;
    render?: (content: ReactNode, vacancyRef: MutableRefObject<HTMLDivElement | undefined>) => ReactNode;
}

const VacancySearchItem: FC<VacancySearchItemProps> = ({
    vacancy,
    vacancySource,
    hhtmFromLabel,
    criteriaText,
    render,
}) => {
    const {
        '@isAdv': isAdv,
        metallic,
        vacancyId,
        searchRid,
        clickUrl,
        '@click': advClickUrl,
        links,
        isVacancyOfTheDay,
    } = vacancy;
    const vacancyRef = useRef<HTMLDivElement>();
    const isMagritteOnVacancySearch = useMagritteOnVacancySearchExp(true);

    const vacancyOfTheDayViewAnalyticsSubscribe = useVacancyOfTheDayAnalytics(
        vacancy,
        VacancyOfTheDayLocation.VacancySearchResult,
        VacancyOfTheDaySpyingProps
    );

    const searchResultShown = useSearchResultShown({ searchType: SearchType.Vacancy, itemId: vacancyId, searchRid });

    const content = (
        <VacancySearchItemContent
            vacancy={vacancy}
            vacancySource={vacancySource}
            hhtmFromLabel={hhtmFromLabel}
            criteriaText={criteriaText}
        />
    );

    const handleAbortEvent = useCallback((e: DragEvent<HTMLDivElement>) => {
        // Блокируем захват ссылок на карточке для корректной работы SwipeWrapper.
        e.preventDefault();
    }, []);

    const url = getVacancyLinkURL({
        criteriaText: criteriaText ?? '',
        isAdv,
        clickUrl,
        advClickUrl,
        isVacancyOfTheDay,
        desktopLink: links.desktop,
    });

    const onCardClick = useCallback(() => {
        window.open(url, '_blank');
    }, [url]);

    const cardElement = isMagritteOnVacancySearch ? (
        <Card
            ref={(element) => {
                vacancyOfTheDayViewAnalyticsSubscribe(element);
                vacancyRef.current = element ?? undefined;
            }}
            data-qa={classnames('vacancy-serp__vacancy', {
                [`vacancy-serp__vacancy_${metallic}`]: metallic,
                'vacancy-serp-item_clickme': isAdv,
            })}
            borderRadius={24}
            padding={12}
            showBorder
            showShadowOnHover
            stretched
            actionCard
            onClick={onCardClick}
        >
            {render ? render(content, vacancyRef) : content}
        </Card>
    ) : (
        <div
            ref={(element) => {
                vacancyOfTheDayViewAnalyticsSubscribe(element);
                vacancyRef.current = element ?? undefined;
            }}
            className={classnames('vacancy-search-item__card', 'serp-item_link', styles.vacancyCardContainer, {
                [styles.vacancyCardClickme]: isAdv,
            })}
            data-qa={classnames('vacancy-serp__vacancy', {
                [`vacancy-serp__vacancy_${metallic}`]: metallic,
                'vacancy-serp-item_clickme': isAdv,
            })}
            onDragStart={handleAbortEvent}
        >
            {render ? render(content, vacancyRef) : content}
        </div>
    );

    return (
        <ElementShownAnchor key={`${vacancyId}${searchRid || ''}`} fn={searchResultShown}>
            <MagritteWrapper isEnabled={isMagritteOnVacancySearch}>
                <BrandedSnippetProvider {...vacancy}>
                    <BrandedSnippet {...vacancy}>{cardElement}</BrandedSnippet>
                </BrandedSnippetProvider>
                {isMagritteOnVacancySearch && <VSpacing default={16} />}
            </MagritteWrapper>
        </ElementShownAnchor>
    );
};

export default translation(VacancySearchItem);
