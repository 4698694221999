import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import styles from './styles.less';

const TrlKeys = {
    online: 'vacancySearch.vacancyCard.employerIsOnline',
};

type Props = {
    isEmployerOnline: boolean;
    isMagritte?: boolean;
};

const VacancyIsEmployerOnline: TranslatedComponent<Props> = ({ trls, isEmployerOnline, isMagritte }) => {
    if (!isEmployerOnline) {
        return null;
    }

    if (isMagritte) {
        return <span className={styles.employerOnlineMagritte} data-qa="vacancy-serp-item-activity" />;
    }

    return (
        <span className={styles.employerOnline} data-qa="vacancy-serp-item-activity">
            {trls[TrlKeys.online]}
        </span>
    );
};

export default translation(VacancyIsEmployerOnline);
