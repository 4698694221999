import { FC } from 'react';

import HrBrandIcon from 'lux/components/HrBrandIcon';

type Props = {
    hrBrand: {
        type: string;
        '@url': string;
        '@year': number;
    };
    isRedesignedSerp?: boolean;
    isMagritte?: boolean;
};

const HRBrand: FC<Props> = ({ hrBrand, isRedesignedSerp, isMagritte }) => {
    if (!hrBrand) {
        return null;
    }
    const hrBrandType = hrBrand.type === 'winner' ? 'winners' : 'nominees';
    return (
        <HrBrandIcon
            hrBrand={{
                [hrBrandType]: {
                    '@url': hrBrand['@url'],
                    '@year': hrBrand['@year'],
                },
            }}
            dataQa="vacancy-serp__vacancy_hrbrand"
            isRedesignedIcon={isRedesignedSerp}
            isMagritte={isMagritte}
        />
    );
};

export default HRBrand;
