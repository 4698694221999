import { FC, useRef } from 'react';

import { TooltipHover } from '@hh.ru/magritte-ui';
import { HrBrandOutlinedCircledSize16 } from '@hh.ru/magritte-ui/product';
import { Link } from '@hh.ru/redux-spa-middleware';
import HoverTip, { TipPlacement, TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import { HrBrandScaleSmallKindColor, HrBrandScaleMediumKindColor, IconColor } from 'bloko/blocks/icon';

import { useSelector } from 'lux/modules/useSelector';

import HrBrandContent from 'lux/components/HrBrandIcon/HrBrandContent';

const IconSizeMap = {
    small: HrBrandScaleSmallKindColor,
    medium: HrBrandScaleMediumKindColor,
} as const;

export type HrBrandIconSize = keyof typeof IconSizeMap;

type Props = {
    hrBrand: {
        nominees?: {
            '@url': string;
        };
        winners?: {
            '@url': string;
        };
    };
    size?: HrBrandIconSize;
    dataQa: string;
    isRedesignedIcon?: boolean;
    isVacancyPage?: boolean;
    isMagritte?: boolean;
};

const HrBrandIcon: FC<Props> = ({ hrBrand, size = 'medium', dataQa, isRedesignedIcon, isVacancyPage, isMagritte }) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    const userType = useSelector((state) => state.userType);
    if (!hrBrand.nominees && !hrBrand.winners) {
        return null;
    }
    const utmCampaign = isVacancyPage ? 'badge' : 'icon';
    const urlParams = `?utm_source=hh.ru&utm_medium=referral&utm_campaign=${utmCampaign}&utm_term=${userType}`;

    const dataQaSpecific = dataQa
        ? Object.keys(hrBrand)
              .map((hrBrandType) => {
                  return `${dataQa}_${hrBrandType}`;
              })
              .join(' ')
        : '';

    const url = `${hrBrand?.nominees?.['@url'] || hrBrand?.winners?.['@url'] || ''}${urlParams}`;

    if (isMagritte) {
        return (
            <Link to={url} target="_blank" rel="nofollow noindex" data-qa={dataQa ? `${dataQa} ${dataQaSpecific}` : ''}>
                <span ref={activatorRef}>
                    <HrBrandOutlinedCircledSize16 initial="warning-secondary" />
                </span>
                <TooltipHover direction="top" alignment="center" activatorRef={activatorRef}>
                    <HrBrandContent hrBrand={hrBrand} />
                </TooltipHover>
            </Link>
        );
    }

    const IconComponent = IconSizeMap[size];

    return (
        <Link to={url} target="_blank" rel="nofollow noindex" data-qa={dataQa ? `${dataQa} ${dataQaSpecific}` : ''}>
            <HoverTip
                render={() => <HrBrandContent hrBrand={hrBrand} />}
                placement={TipPlacement.Top}
                layer={TipLayer.Overlay}
                host={!process.env.LUX_SERVER ? document.body : null}
            >
                {isRedesignedIcon ? (
                    <span className={'vacancy-serp-bage-hr-brand'} />
                ) : (
                    <IconComponent initial={IconColor.Black} />
                )}
            </HoverTip>
        </Link>
    );
};
export default HrBrandIcon;
